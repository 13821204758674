import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react'
import React from 'react'
import {ChevronRight} from 'wix-ui-icons-common'
import Location from 'wix-ui-icons-common/LocationSmall'
import {TEXT_BUTTON_PRIORITY, TextButton} from 'wix-ui-tpa/TextButton'
import {useExperiments} from 'yoshi-flow-editor-runtime'
import classNames from 'classnames'
import {DH} from '../../../data-hooks'
import settingsParams from '../../../settingsParams'
import {Tags} from '../../filters/tags'
import {ItemDivider} from '../divider'
import {Time} from '../time'
import {
  getScheduleItemDescription,
  isScheduleItemCanceled,
  isScheduleItemWithIndication,
} from '../../../selectors/schedule'
import {Indications} from '../../indications'
import * as s from './mobile.scss'
import {classes} from './mobile.st.css'
import type {ItemProps} from '.'

export const MobileItem = ({item, showDivider, toggleScheduleItemModal, readDescriptionClicked, t}: ItemProps) => {
  const {experiments} = useExperiments()
  const {formattedUpdatedDate, formattedTimeSlot, formattedDuration, name, location, tags} = item
  const settings = useSettings()
  const showDuration = settings.get(settingsParams.showDuration)
  const descriptionButtonText = settings.get(settingsParams.readMore)
  const description = getScheduleItemDescription(item)
  const canceled = isScheduleItemCanceled(item)
  const scheduleUpdatedIndicationEnabled = experiments.enabled('specs.events.ui.ScheduleUpdatedIndication')

  const handleReadMore = () => {
    readDescriptionClicked({itemId: item.id})
    toggleScheduleItemModal(item)
  }

  return (
    <li>
      <Indications
        canceled={canceled}
        t={t}
        formattedUpdatedDate={formattedUpdatedDate}
        scheduleUpdatedIndicationEnabled={scheduleUpdatedIndicationEnabled}
      />
      <div
        className={classNames(s.container, {
          [s.itemWithIndication]: isScheduleItemWithIndication(item, scheduleUpdatedIndicationEnabled),
        })}
        data-hook={DH.MOBILE_SCHEDULE_ITEM}
      >
        <Time className={s.timeSlot} data-hook={DH.SCHEDULE_ITEM_DATETIME} dateTime={formattedTimeSlot}>
          {formattedTimeSlot}
        </Time>
        {showDuration && (
          <div className={s.duration} data-hook={DH.SCHEDULE_ITEM_DURATION}>
            {formattedDuration}
          </div>
        )}
        <div className={s.title} data-hook={DH.SCHEDULE_ITEM_NAME}>
          {name}
        </div>
        {location && (
          <div className={s.location} data-hook={DH.LOCATION}>
            <Location data-hook={DH.LOCATION_ICON} />
            <span>{location}</span>
          </div>
        )}
        {tags && <Tags t={t} tags={tags} className={s.tags} />}
        {description && (
          <TextButton
            className={classes.readMore}
            priority={TEXT_BUTTON_PRIORITY.primary}
            data-hook={DH.DESCRIPTION_BUTTON}
            suffixIcon={<ChevronRight />}
            onClick={handleReadMore}
          >
            {descriptionButtonText}
          </TextButton>
        )}
      </div>
      {showDivider && <ItemDivider />}
    </li>
  )
}
