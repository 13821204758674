import * as React from 'react'
import {NOTIFICATION_SIZE, NOTIFICATION_TYPE} from 'wix-ui-tpa/SectionNotification'
import classNames from 'classnames'
import {Error} from '../icons'
import {SectionNotification} from '../schedule/item/section-notification'
import {UpdatedIndication} from '../schedule/item/updated-indication'
import * as s from './indications.scss'
import {IndicationsProps} from '.'

export const Indications = ({
  canceled,
  t,
  formattedUpdatedDate,
  scheduleUpdatedIndicationEnabled,
  inModal,
}: IndicationsProps) => {
  const shouldUpdatedIndicationShow = scheduleUpdatedIndicationEnabled && !canceled && formattedUpdatedDate
  return (
    <>
      {canceled ? (
        <SectionNotification
          size={NOTIFICATION_SIZE.compact}
          type={NOTIFICATION_TYPE.wired}
          className={classNames(s.cancelNotification, {[s.inModal]: inModal})}
          icon={<Error className={s.canceledIcon} />}
          text={t('itemCanceled')}
        />
      ) : null}
      {shouldUpdatedIndicationShow ? (
        <UpdatedIndication t={t} formattedUpdatedDate={formattedUpdatedDate} inModal={inModal} />
      ) : null}
    </>
  )
}
