import * as React from 'react'
import {Dialog} from 'wix-ui-tpa/Dialog'
import Location from 'wix-ui-icons-common/LocationSmall'
import {Text} from 'wix-ui-tpa/Text'
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react'
import classNames from 'classnames'
import {useExperiments} from 'yoshi-flow-editor-runtime'
import {DH} from '../../../data-hooks'
import {Tags} from '../../filters/tags'
import settingsParams from '../../../settingsParams'
import * as ms from '../../schedule/item/mobile.scss'
import {classes as descriptionClasses} from '../../schedule/description/description.st.css'
import {classes as modalClasses} from '../modal.st.css'
import {getScheduleItemDescription, isScheduleItemCanceled} from '../../../selectors/schedule'
import {Indications} from '../../indications'
import {classes} from './schedule-item.st.css'
import {ScheduleItemProps} from '.'

export const ScheduleItem = ({item, toggleScheduleItemModal, host, t}: ScheduleItemProps) => {
  const settings = useSettings()
  const {experiments} = useExperiments()
  const showDuration = settings.get(settingsParams.showDuration)
  const preview = host.viewMode === 'Preview'
  const description = getScheduleItemDescription(item)
  const canceled = isScheduleItemCanceled(item)
  const scheduleUpdatedIndicationEnabled = experiments.enabled('specs.events.ui.ScheduleUpdatedIndication')

  return (
    <Dialog
      className={classNames(modalClasses.root, {[modalClasses.previewMode]: preview})}
      contentClassName={classes.modalContent}
      isOpen={true}
      onClose={() => toggleScheduleItemModal(null)}
    >
      <div className={classes.container}>
        <Indications
          canceled={canceled}
          t={t}
          formattedUpdatedDate={item.formattedUpdatedDate}
          scheduleUpdatedIndicationEnabled={scheduleUpdatedIndicationEnabled}
          inModal
        />
        <div className={ms.timeSlot}>{item.formattedTimeSlot}</div>
        {showDuration && <div className={ms.duration}>{item.formattedDuration}</div>}
        <div className={ms.title}>{item.name}</div>
        {item.location && (
          <div className={ms.location} data-hook={DH.LOCATION}>
            <Location data-hook={DH.LOCATION_ICON} />
            <span>{item.location}</span>
          </div>
        )}
        {item.tags && <Tags t={t} tags={item.tags} className={ms.tags} />}
        {description && (
          <Text className={descriptionClasses.text} data-hook={DH.DESCRIPTION}>
            <div dangerouslySetInnerHTML={{__html: description}} />
          </Text>
        )}
      </div>
    </Dialog>
  )
}
